<template>
  <div>
    <div class="head-bar min-page flex-cc top-fixed">
      <div class="flex-bc page">
        <div class="logo">
          <h1>现代日语学校</h1>
          <img :src="$img('Logo')" />
        </div>
        <div class="flex-c">
          <div class="flex-c navs">
            <div class="nav" v-for="(item, index) in navs" :ref="'nav' + index" :key="index" :class="{active: highlight === index}" @click="goNav(item, index)">
              {{ item.name }}
              <div v-if="item.children && item.children.length" class="children brrs">
                <div v-for="(i, k) in item.children" :key="k" class="children-item" @click="goNav(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
            <div class="line" :style="[lineStyle]"></div>
          </div>
          <template v-if="isLogin">
            <div class="flex-c user">
              <img :src="user_info.userpic" alt="">
              <div>
                <div class="nickname">{{user_info.nickname}}</div>
                <div class="student">学号：{{user_info.userid}}</div>
              </div>
              <div v-if="children && children.length" class="children">
                <div v-for="(i, k) in children" :key="k" class="children-item" @click="goNav(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="login-btn" @click="getLoginCode">登录</div>
          </template>
        </div>
      </div>
    </div>
    <div class="head-bar min-page"></div>
    <van-popup v-model="loginShow" overlay-class="black-bg">
      <div class="popup-box">
        <div class="popup-title">登录注册</div>
        <img :src="loginInfo.qrcode_url" class="login-img">
        <div class="login-toast-two">微信扫码</div>
        <div class="login-toast">关注<span>【现代日语公众号】</span>即可登录</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapActions } from 'vuex'
  export default {
    name: 'head-bar',
    data () {
      return {
        loginShow: false,
        random_code: 0,
        loginInfo: {},
        lineStyle: {},
        navs: [
          {
            name: '首页',
            url: 'Common.Home',
            save: { sign: '' }
          },
          {
            name: '高考日语',
            url: 'Common.ShowOffCexJapanese',
            highlight: 1
          },
          {
            name: '学院介绍',
            url: 'Common.AboutUs',
            highlight: 2,
            query: {
              navActive: 1
            }
          },
          {
            name: '师资介绍',
            url: 'Common.AboutUs',
            highlight: 3,
            query: {
              navActive: 2
            }
          },
          {
            name: '线上课程',
            url: 'Campus.OnlineCourses',
            highlight: 4
          },
          {
            name: '五十音图',
            url: 'Common.Broadcast',
            highlight: 5
          },
          {
            name: '联系我们',
            url: 'Common.ContactUs',
            highlight: 6
          }
          // {
          //   name: '学院介绍',
          //   url: 'Common.Home',
          //   save: { sign: 'Academy' }
          // },
          // {
          //   name: '师资介绍',
          //   url: 'Common.Home',
          //   save: { sign: 'Teachers' }
          // },
          // {
          //   name: '校企合作',
          //   url: 'Common.Home',
          //   save: { sign: 'SchoolEnterprise' }
          // },
          // {
          //   name: '校园资讯',
          //   url: 'Common.Home',
          //   save: { sign: 'Information' }
          // },
          // {
          //   name: '线下报名',
          //   url: 'Common.Home',
          //   save: { sign: 'Offline' }
          // }
        ],
        children: [
          {
            name: '我的课程',
            url: 'Course.MyCourse'
          },
          {
            name: '优惠中心',
            url: 'Course.Discounts'
          },
          {
            name: '我的优惠券',
            url: 'Course.MyDiscounts'
          },
          {
            name: '报名订单',
            url: 'Course.ApplyOrder'
          },
          {
            name: '绑定账号',
            url: 'Course.Binding'
          },
          {
            name: '退出登录',
            fun: 'loginQuit'
          }
        ]
      }
    },
    computed: {
      ...mapState('Common', ['isLogin', 'highlight', 'user_info'])
    },
    watch: {
      loginShow (to) {
        if (!to) {
          clearInterval(this.loginTime)
          if (!this.isLogin) {
            this.$SetStorage('process', '')
          }
        }
      },
      highlight () {
        this.setLine()
      },
      $route (to) {
        console.log('to-----------------', to)
        if (to.name === 'Common.AboutUs') {
          if (typeof (this.$route.query.navActive) !== 'undefined') {
            this.SaveHighlight(parseInt(this.$route.query.navActive) === 1 ? 2 : 3)
          }
        }
      }
    },
    methods: {
      loginQuit () {
        this.SaveIsLogin(false)
        this.$RemoveStorage('ZTBTOKEN', 'local')
        this.$nav('Common.Home', {}, 'reLaunch')
        setTimeout(() => {
          location.reload()
        }, 500)
      },
      async getLoginCode () {
        this.random_code = this.$Tool.GetRndStringNum(12)
        let { status, data } = await this.$Post('Common.createQrcode', {
          random_code: this.random_code
        })
        if (status) {
          this.loginInfo = data
          this.loginShow = true
          clearInterval(this.loginTime)
          this.loginTime = setInterval(() => {
            this.getWxLoginCodeByFind()
          }, 3000)
        }
      },
      async getWxLoginCodeByFind () {
        let { status, data } = await this.$Post('Common.getQrcodeToken', {
          random_code: this.random_code
        }, { loading: false })
        if (status) {
          if (data.is_use * 1 === 1) {
            clearInterval(this.loginTime)
            this.$SetStorage('ZTBTOKEN', data.token, 'local')
            // this.userCenter()
            // this.loginShow = false
            location.reload()
          } else if (data.is_use * 1 === 2) {
            clearInterval(this.loginTime)
            this.loginShow = false
            this.$ShowToast('登录二维码已经被使用过了')
          } else if (data.is_use * 1 === -1) {
            clearInterval(this.loginTime)
            this.loginShow = false
            this.$ShowToast('登录二维码不存在随机码')
          }
        }
      },
      goNav (item) {
        if (item.save) {
          this.SaveState(item.save)
        }
        //  && item.url !== this.$route.name
        if (item.url) {
          this.$nav(item.url, item.query || {})
        } else if (item.fun) {
          this[item.fun]()
        }

        if (typeof (item.highlight) !== 'undefined') {
          this.SaveHighlight(item.highlight)
        }
      },
      setLine () {
        if (this.highlight > -1 && this.$refs['nav' + this.highlight] && this.$refs['nav' + this.highlight][0]) {
          let porp = this.$refs['nav' + this.highlight][0]
          this.lineStyle = {
            width: porp && porp['scrollWidth'] + 'px',
            left: porp && porp['offsetLeft'] + 'px'
          }
        } else {
          this.lineStyle = {
            width: 0,
            left: 0
          }
        }
      },
      ...mapMutations('Common', ['SaveIsLogin', 'SaveState', 'SaveHighlight']),
      ...mapActions('Common', ['userCenter'])
    },
    mounted () {
      this.$Bus.$on('goLogin', () => {
        this.getLoginCode()
      })
    }
  }
</script>

<style lang="less" scoped>
.popup-box {
  width: 360px;
  height: 338px;
  .popup-title {
    margin-bottom: 16px;
  }
  .login-img {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
  }
  .login-toast-two {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 8px;
  }
  .login-toast {
    font-size: 14px;
    color: rgb(16, 16, 16);
    text-align: center;
    span {
      color: #CA0000;
      font-size: 15px;
    }
  }
}
.head-bar {
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  z-index: 9999;
  .user {
    height: 72px;
    .nickname {
      max-width: 100px;
      height: 19px;
      font-size: 14px;
      line-height: 19px;
      color: #333333;
      margin-bottom: 4px;
      .line-clamp-multi(1);
    }
    .student {
      height: 16px;
      font-size: 12px;
      line-height: 16px;
      color: #B2B2B2;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .children {
      padding: 10px 0;
    }
    &:hover {
      .children {
        display: block;
      }
    }
  }
  .navs {
    height: 72px;
    position: relative;
    .line {
      position: absolute;
      bottom: 0;
      background-color: #CA0000;
      height: 3px;
      transition: all .5s;
    }
    .nav {
      font-size: 16px;
      height: 100%;
      box-sizing: border-box;
      margin-right: 38px;
      cursor: pointer;
      position: relative;
      .flex-cc();
      &:active,&:hover,&.active {
        color: #CA0000;
        // border-bottom: solid 3px #CA0000;
      }
      &:hover {
        .children {
          display: block;
        }
      }
    }
  }
  h1 {
    font-size: 0;
    color: #333;
    font-weight: bold;
    position: absolute;
  }
  .logo img {
    width: 200px;
  }
  .login-btn {
    width: 72px;
    height: 40px;
    background: #CA0000;
    box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
    opacity: 1;
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    line-height: normal;
    cursor: pointer;
    .flex-cc();
  }
  .brrs .children-item{
    border-radius: 10px;
  }
  .children {
    position: absolute;
    display: none;
    top: 72px;
    width: 150px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 0 0 10px 10px;
    z-index: 100;
    .children-item {
      cursor: pointer;
      .flex-cc();
      width: 150px;
      height: 60px;
      font-size: 14px;
      background: #fff;
      color: #888888;
      &:active,&:hover {
        color: #CA0000;
        background: #F6F6F6;
      }
    }
    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   top: -20px;
    //   width: 0;
    //   height: 0;
    //   border-left: 13px solid transparent;
    //   border-right: 13px solid transparent;
    //   border-bottom: 20px solid #F6F6F6;
    // }
  }
}
</style>
