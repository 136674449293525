import { InitConfigInfo, GetApi as _GetApi } from './lib'
import {
  UploadFile as _UploadFile,
  Request as _Request
} from './request'

export const GetApi = _GetApi
export const UploadFile = _UploadFile
export const Request = _Request

export default {
  install: (Vue, option = {}) => {
    // 存储option信息
    // const {
    //   // api格式
    //   apiFormat,
    //   // 域名
    //   hostUrl,
    //   // 端名
    //   controlName,
    //   // api信息对象
    //   apiObj,
    //   // 全局请求头
    //   header,
    //   // 全局请求方式
    //   method,
    //   // 成功返回码
    //   successCode,
    //   // 忽略错误提示返回码
    //   ignoreToastCode,
    //   // 是否强制回调then
    //   forceReturn,
    //   // 打开加载层回调
    //   showLoadingHandle,
    //   // 关闭加载层回调
    //   closeLoadingHandle,
    //   // catch请求失败回调
    //   catchRequestHandle,
    //   // 数据解析的回调函数
    //   parseData,
    //   // toast提示回调
    //   toastHandle,
    //   // code返回处理
    //   codeHandle
    // } = option
    InitConfigInfo(option)
    // 全局注册GetApi方法
    Vue.prototype.$GetApi = _GetApi
    // 全局注册UploadFile方法
    Vue.prototype.$UploadFile = _UploadFile
    // 全局注册请求方法
    Vue.prototype.$Request = _Request
    // 全局注册请求方法(Get)
    Vue.prototype.$Get = (apiName, data = {}, option = { loading: false }) => {
      return new Promise((resolve, reject) => {
        _Request(apiName, data, {
          ...option,
          method: 'GET'
        }).then(res => resolve(res))
      })
    }
    // 全局注册请求方法(Post)
    Vue.prototype.$Post = (apiName, data = {}, option = {}) => {
      return new Promise((resolve, reject) => {
        _Request(apiName, data, {
          ...option,
          method: 'POST'
        }).then(res => resolve(res))
      })
    }
  },
  GetApi: _GetApi,
  UploadFile: _UploadFile,
  Request: _Request
}
