export default {
  // 校区详情
  CampusDetail: {
    meta: {
      title: '校区详情',
      highlight: 6
    }
  },
  // 线上课程
  OnlineCourses: {
    meta: {
      title: '线上课程',
      highlight: 4
    }
  },
  // 试看课程详情
  CheckCourseDetails: {
    meta: {
      title: '课程详情',
      highlight: 4,
      parent: [
        {
          title: '线上课程',
          url: 'Campus.OnlineCourses'
        }
      ]
    }
  },
  // 系列课程
  SeriesCourses: {
    meta: {
      title: '系列课程',
      highlight: 1,
      parent: [
        {
          title: '线上课程',
          url: 'Campus.OnlineCourses'
        }
      ]
    }
  },
  // 系列课程详情
  SeriesCoursesDetails: {
    meta: {
      title: '课程详情',
      highlight: 1,
      parent: [
        {
          title: '线上课程',
          url: 'Campus.OnlineCourses'
        }
      ]
    }
  },
  SubtitlesDetail: {
    meta: {
      title: '课文详情',
      highlight: 1,
      parent: [
        {
          title: '线上课程',
          url: 'Campus.OnlineCourses'
        }
      ]
    }
  },
  // 线上习题
  OnlineProblemSets: {
    meta: {
      title: '线上习题',
      highlight: 1,
      parent: [
        {
          title: '线上课程',
          url: 'Campus.OnlineCourses'
        }
      ]
    }
  },
  // 习题详情
  ProblemSetsDetails: {
    meta: {
      title: '线上习题',
      highlight: 1,
      parent: [
        {
          title: '我的课程',
          url: 'Course.MyCourse'
        }
      ]
    }
  },
  // 习题结果详情
  ResultDetails: {
    meta: {
      title: '线上习题',
      highlight: 1,
      parent: [
        {
          title: '我的课程',
          url: 'Course.MyCourse'
        }
      ]
    }
  }
}
