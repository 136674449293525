export const CodeValue = {
  // 答题类型
  AnswerType: [{
    code: 1,
    value: '听力题'
  }, {
    code: 2,
    value: '选择题'
  }, {
    code: 3,
    value: '填空题'
  },
  {
    code: 4,
    value: '阅读题'
  },
  {
    code: 5,
    value: '作文题'
  }
  ],
  // 优惠券类型
  CouponType: [{
    code: 1,
    value: '满减券'
  },
  {
    code: 2,
    value: '折扣券'
  }
  ]
}

/**
 * 过滤函数
 */
export const FilterCode = (type, code) => {
  const CodeItem = CodeValue[type]
  if (CodeItem) {
    const FilterItem = CodeItem.filter(({
      code: _code
    }) => _code === code || _code.toString() === code)
    if (FilterItem.length > 0) {
      return FilterItem[0].value
    } else {
      return null
    }
  } else {
    return null
  }
}

export default {
  install: Vue => {
    Vue.prototype.$CodeValue = CodeValue
    Vue.prototype.$FilterCode = FilterCode
  }
}
