<template>
  <div>
    <van-popup v-model="applyshow" overlay-class="black-bg">
      <div class="popup-box">
        <div class="popup-title">立即报名</div>
        <div class="popup-line">
          <div class="popup-label">报名校区</div>
          <div class="popup-text">{{school_info.school_name}}</div>
        </div>
        <div class="popup-line flex-c">
          <div class="popup-label"><span>*</span>姓名</div>
          <div class="popup-text">
            <el-input v-model="name" placeholder="请输入姓名"></el-input>
          </div>
        </div>
        <div class="popup-line flex-c">
          <div class="popup-label"><span>*</span>手机号码</div>
          <div class="popup-text"><el-input v-model="phone" placeholder="请输入手机号码"></el-input></div>
        </div>
        <div class="popup-line flex-c">
          <div class="popup-label">优惠券</div>
          <div class="popup-text">
            <el-select v-model="value" placeholder="请选择优惠券">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="popup-line">
          <div class="popup-label">支付金额</div>
          <div class="popup-text text1"><span>￥</span>{{order_info.condition.order_amount}}</div>
        </div>
        <div class="popup-line">
          <div class="popup-label">支付方式</div>
          <div class="flex-c">
            <div class="popup-select" :class="{active: pay === 'wx'}" @click="pay = 'wx'">
              <img :src="$img('IconWx')" />
              <span>微信支付</span>
              <img :src="$img('IconCheckedFillTwo')" class="checked" v-if="pay === 'wx'" />
            </div>
            <!-- <div class="popup-select" :class="{active: pay === 'zfb'}" @click="pay = 'zfb'">
              <img :src="$img('IconZfb')" />
              <span>支付宝</span>
              <img :src="$img('IconCheckedFillTwo')" class="checked" v-if="pay === 'zfb'" />
            </div> -->
          </div>
        </div>
        <div class="flex-cc agreement" @click="checked = !checked">
          <img :src="checked ? $img('IconCheckedFill') : $img('IconChecked')" class="checked" />
          我已阅读并同意<span @click.stop="goAgreement">《售后协议》</span>
        </div>
        <div class="popup-btn" @click="createOrder">确认支付</div>
        <div class="wxpay" v-if="wxpay">
          <img :src="$img('IconClose')" class="close" @click.stop="wxpay = false">
          <img :src="wxpayurl" width="500" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        checked: false,
        applyshow: false,
        phone: '',
        name: '',
        options: [],
        value: '',
        pay: '',
        info: {},
        school_info: {},
        order_info: {
          goods: {},
          condition: {},
          school: []
        },
        payTime: null,
        wxpayurl: '',
        wxpay: false,
        id: ''
      }
    },
    methods: {
      Reset () {
        this.name = ''
        this.phone = ''
        this.value = ''
        this.pay = ''
      },
      async confirmOrder (sign = false) {
        let { status, data } = await this.$Post('Common.OfflineConfirmOrder', {
          goods_id: this.id,
          school_id: this.school_info.school_id,
          coupon_code: this.value
        }, {
          loading: false
        })
        if (status) {
          this.order_info = data
          if (sign) {
            this.options = data.coupon_list && data.coupon_list.map(item => ({
              value: item.code,
              label: item.couponFind.coupon_name
            }))
            this.options.unshift({
              value: '',
              label: '不使用优惠券'
            })
          }
          this.applyshow = true
        } else {
          this.$SetStorage('process', {
            key: 'OfflinePay',
            data: {
              id: this.id,
              school_info: this.school_info
            }
          })
        }
      },
      async createOrder () {
        if (!this.checked) {
          return this.$ShowToast('请勾选协议')
        }
        if (!this.name || !this.phone) {
          return this.$ShowToast('请完善信息')
        }
        if (!this.pay) {
          return this.$ShowToast('请选择支付方式')
        }
        let { status, data } = await this.$Post('Common.OfflineCreateOrder', {
          goods_id: this.id,
          school_id: this.school_info.school_id,
          coupon_code: this.value,
          user_name: this.name,
          phone: this.phone
        })
        if (status) {
          if (data.is_pay * 1 === 0) {
            this.OrderAccount(data.order_sn)
          } else {
            this.$ShowToast('报名成功', { icon: 'success' })
            this.applyshow = false
            location.reload()
          }
        }
      },
      async OrderAccount (sn) {
        let reqURL = ''
        switch (this.pay) {
        case 'wx':
          reqURL = 'Common.OfflineWebPay'
          break
        case 'zfb':
          reqURL = 'Common.OfflineAlipay'
          break
        }
        let { status, data } = await this.$Post(reqURL, {
          order_sn: sn,
          success_url: location.href,
          failure_url: location.href
        })
        if (status) {
          switch (this.pay) {
          case 'wx':
            this.wxpayurl = data.qr_code
            this.wxpay = true
            break
          case 'zfb':
            window.open(data.pay_url)
            break
          }
          clearInterval(this.payTime)
          this.payTime = setInterval(() => {
            this.orderDetails(sn)
          }, 3000)
        }
      },
      async orderDetails (sn) {
        let { status, data } = await this.$Get('Common.OfflineOrderDetails', {
          order_sn: sn
        })
        if (status) {
          if (data.order.pay_status * 1 === 1) {
            this.$ShowToast('报名成功', { icon: 'success' })
            this.applyshow = false
            this.wxpay = false
          }
        }
      },
      goAgreement () {
        // this.$nav('Common.Agreement')
        window.open(location.origin + '/Common/Agreement')
      }
    },
    watch: {
      applyshow (to) {
        clearInterval(this.payTime)
        this.wxpay = false
        if (to) {
          this.Reset()
        }
      },
      value () {
        this.confirmOrder()
      }
    },
    mounted () {
      this.$Bus.$on('OfflinePay', (data = {}) => {
        this.id = data.id
        this.school_info = data.school_info
        this.confirmOrder(true)
      })
    }
  }
</script>

<style lang="less" scoped>
  .popup-box {
    width: 680px;
    height: 769px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 24px 60px 0 50px;
    z-index: 9999999;
    position: relative;
    overflow: auto;
    .wxpay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      .flex-ccc();
      background-color: rgba(0, 0, 0, 0.7);
      img {
        width: 300px;
      }
      .close {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
    }
    .popup-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
      text-align: center;
      margin-bottom: 36px;
    }
    .popup-line {
      margin-bottom: 36px;
      .flex();
      .popup-label {
        width: 80px;
        font-size: 16px;
        margin-right: 16px;
        line-height: 24px;
        color: #888888;
        text-align: right;
        span {
          color: red;
        }
      }
      .popup-text {
        .flex-1();
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #333333;
        &.text1 {
          font-size: 20px;
          font-family: WeChatNumber-151125;
          font-weight: 500;
          line-height: 24px;
          color: #CA0000;
          span {
            font-size: 12px;
          }
        }
      }
      .popup-select {
        width: 160px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        margin-right: 30px;
        border-radius: 10px;
        font-size: 18px;
        line-height: normal;
        color: #333333;
        position: relative;
        .flex-cc();
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
        .checked {
          position: absolute;
          width: 24px;
          height: 24px;
          right: 0;
          top: 0;
          margin-right: 0;
        }
        &.active {
          border-color: #CA0000;
        }
      }
    }
    .agreement {
      margin-top: 80px;
      font-size: 14px;
      line-height: 24px;
      color: #b2b2b2;
      margin-bottom: 25px;
      cursor: pointer;
      span {
        color: #6A8EBC;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
    .popup-btn {
      width: 180px;
      height: 50px;
      background: #CA0000;
      box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
      opacity: 1;
      border-radius: 25px;
      font-size: 18px;
      color: #fff;
      margin: auto;
      cursor: pointer;
      .flex-cc();
    }
    /deep/ .el-input input {
      height: 54px;
      font-size: 16px;
      border-radius: 10px;
    }
  }
</style>
