export default {
  SaveIsLogin (state, info) {
    state.isLogin = info
  },
  SaveUserInfo (state, info) {
    state.user_info = info
  },
  SaveUserOrder (state, info) {
    state.user_order = info
  },
  SaveHighlight (state, info) {
    console.log('SaveHighlight-----', info)
    if (info !== 'follow') {
      state.highlight = info
    }
  },
  SaveState (state, info) {
    console.log('SaveState-----', info)

    Object.keys(info).forEach(i => {
      state[i] = info[i]
    })
  }
}
