<template>
  <div class="footer min-page flex-ccc" :style="cutomStyle">
    <div class="flex-c page flex-1">
      <div class="logo">
        <h2>现代日语学校</h2>
        <img :src="$img('Logo')" />
      </div>
      <div class="footer-main">
        <div class="flex mb16">
          <img :src="$img('IconAddress')" />
          <div class="footer-text">
            韶关市武江区南枫花园B座3楼 韶关现代汉语学校（新华南这个门口上）
            <div class="footer-text gomap" @click="goMap">点击查看地图位置</div>
          </div>
        </div>
        <div class="flex mb16">
          <img :src="$img('IconPhone')" />
          <div class="footer-text"><span>0751-8766159</span><span>0751-8766149</span></div>
        </div>
        <div class="flex">
          <img :src="$img('IconEmail')" />
          <div class="footer-text">541041680@qq.com</div>
        </div>
      </div>
      <div class="footer-block">
        <!-- <img :src="$img('CodeBg')" class="codebg" /> -->
        <div class="footer-img flex-ccc">
          <span>手机端入口</span>
          <img :src="$img('Code')" class="code" />
        </div>
      </div>
      <div class="footer-block footer-block1">
        <!-- <img :src="$img('CodeBg')" class="codebg" /> -->
        <div class="footer-img flex-ccc">
          <span>官方公众号</span>
          <img :src="$img('Code2')" class="code" />
        </div>
      </div>
    </div>
    <div class="flex-cc page internet" @click="goBeiAn">版权所有<span>翁源县现代日语培训中心</span>粤ICP备19131533号</div>
  </div>
</template>

<script>
  export default {
    name: 'footer-bar',
    props: {
      cutomStyle: [String]
    },
    methods: {
      goMap () {
        window.open('https://apis.map.qq.com/uri/v1/geocoder?coord=24.790501,113.586297&coord_type=2')
      },
      goBeiAn(){
        this.$ShowToast('sssssssssss')
        window.open('https://beian.miit.gov.cn/')
      }
    }
  }
</script>

<style lang="less" scoped>
.footer {
  height: 314px;
  background: #FFFFFF;
  box-sizing: border-box;
  // border-top: 10px solid #CA0000;
  position: relative;
  // margin-top: 50px;
  h2 {
    font-size: 0;
    color: #333;
    font-weight: bold;
    margin-right: 192px;
    position: absolute;
  }
  .logo img {
    width: 200px;
    margin-right: 192px;
  }
  .footer-block {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 180px;
    width: 120px;
    .codebg {
      width: 242px;
      height: 281px;
    }
    .footer-img {
      span {
        margin-bottom: 11px;
        font-size: 16px;
        color: #333;
      }
      .code {
        width: 120px;
        height: 120px;
      }
    }
    &.footer-block1 {
      right: 0;
    }
  }
  .footer-main {
    width: 400px;
    img {
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 10px;
      margin-top: 2px;
    }
    .footer-text {
      font-size: 16px;
      line-height: 24px;
      color: #888888;
      user-select: text;
      .line-clamp-multi(2);
      span {
        margin-right: 10px;
        user-select: text;
      }
      &.gomap {
        user-select: none;
        cursor: pointer;
      }
    }
    .mb16 {
      margin-bottom: 16px;
    }
  }
  .internet {
    height: 64px;
    font-size: 12px;
    line-height: 20px;
    color: #B2B2B2;
    border-top: 1px solid #E5E5E5;
    cursor: pointer;
    span {
      margin: 0 10px;
    }
  }
}
</style>
