export default {
	// 首页
	Home: {
		meta: {
			title: '首页',
			highlight: 0,
			isHome: true
		}
	},
	// 校企合作
	SchoolEnterprise: {
		meta: {
			title: '校企合作',
			highlight: 4
		}
	},
	// 校园资讯
	Information: {
		meta: {
			title: '校园资讯',
			highlight: 5
		}
	},
	// 校园资讯
	Teachers: {
		meta: {
			title: '师资介绍',
			highlight: 3
		}
	},
	// 介绍详情
	IntroduceDetail: {
		meta: {
			title: '介绍详情',
			highlight: 'follow'
		}
	},
	// 学院介绍
	Academy: {
		meta: {
			title: '学院介绍',
			highlight: 2
		}
	},
	// 联系我们
	ContactUs: {
		meta: {
			title: '联系我们',
			highlight: 6
		}
	},
	// 线下报名
	OfflineRegistration: {
		meta: {
			title: '线下报名',
			highlight: 6
		}
	},
	// 协议
	Agreement: {
		meta: {
			title: '售后协议',
			highlight: 0
		}
	},
	ShowOff: {
		meta: {
			title: '学院简介',
			highlight: 0
		}
	},
	ShowOffCampusStyle: {
		meta: {
			title: '校园风采',
			highlight: 0
		}
	},
	ShowOffCampusStyleCampusVoice: {
		meta: {
			title: '校园心声',
			highlight: 0
		}
	},
	ShowOffCexJapanese: {
		meta: {
			title: '高考日语',
			highlight: 1
		}
	},
	ShowOffSchoolPerformance: {
		meta: {
			title: '办学成绩',
			highlight: 0
		}
	},
	Broadcast: {
		meta: {
			title: '五十音图',
			highlight: 5
		}
	},
	Seminar: {
		meta: {
			title: '我校正式成为 广东省高考研究会 常务理事团体',
			highlight: 0
		}
	},
	AboutUs: {
		meta: {
			title: '关于我们',
			highlight: 2
		}
	},
	RichText: {
		meta: {
			title: '',
		}
	}
}
