import Vue from 'vue'

export const Bus = new Vue()

export const EmitType = {
  // 打开通知窗口
  OpenRegisterSucc: 'OpenRegisterSucc'
}

export default {
  install: Vue => {
    Vue.prototype.$EmitType = EmitType
    Vue.prototype.$Bus = Bus
  }
}
