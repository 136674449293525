import { nav } from '@/pages'
import Interactive from '@/utils/Interactive'
import store from '@/store'
import { RemoveStorage } from '@/utils/Storage'
import Vue from 'vue'
export const codeHandle = {
  '506': (res, again) => {
    store.commit('Common/SaveIsLogin', false)
    RemoveStorage('ZTBTOKEN', 'local')
    Vue.prototype.$Bus.$emit('goLogin')
  },
  '508': (res, again) => {
    Interactive.ShowToast('您被拉进黑名单无法进行操作')
    nav('Common.Home')
  },
  '708': (res, again) => {
    Interactive.ShowToast('手机号用户不存在')
  }
}

export const showLoadingHandle = () => {
  Interactive.ShowLoading()
}

export const closeLoadingHandle = () => {
  Interactive.CloseLoading()
}

export const catchRequestHandle = erro => {
  console.log('服务器繁忙', erro)
  Interactive.ShowToast('服务器繁忙', { icon: 'fail' })
}

export const toastHandle = res => {
  Interactive.ShowToast(res.msg)
}

export const parseData = res => res
