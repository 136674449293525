<template>
  <div class="title-nav">
    <slot>
      <template v-if="$route.meta.parent">
        <span v-for="(item, index) in $route.meta.parent" :key="index" @click="$nav(item.url)">{{item.title}} >> </span>
      </template>
      {{$route.meta.title}}
    </slot>
  </div>
</template>

<script>
  export default { 
  }
</script>

<style lang="less" scoped>
  .title-nav {
    padding: 36px 0 36px 14px;
    position: relative;
    font-size: 18px;
    span {
      color: #B2B2B2;
      cursor: pointer;
    }
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 21px;
      background: linear-gradient(180deg, #CA0000 0%, rgba(202, 0, 0, 0) 100%);
      border-radius: 4px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
</style>
