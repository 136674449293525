import { Request } from '@/utils/Request/request.js'
export default {
  async userCenter (store) {
    let { commit } = store
    let { data, status } = await Request('Common.userCenter', {}, {
      method: 'POST'
    })
    if (status) {
      commit('SaveUserInfo', data.userInfo)
      commit('SaveUserOrder', data.user_order)
      commit('SaveIsLogin', true)
    }
  }
}
