import {
  CodeValue,
  FilterCode
} from '@/utils/CodeValue'
/**
 * 两位小数格式化
 */
export const formatNumber = n => {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

/**
 * 价格逗号格式化
 */
export const PriceFormat = str => {
  str += ''
  let x = str.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

/**
 * 数字精度处理
 */
export const PrecisionNumber = (n, m = 2) => {
  if (n * 1 || n * 1 === 0) {
    return Number(n).toFixed(m)
  }
  return n
}

/**
 * 数字精度处理
 */
export const AnswerType = (code) => {
  return FilterCode(CodeValue.AnswerType, code)
}
