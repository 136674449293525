export default {
  // 我的课程
  MyCourse: {
    meta: {
      title: '我的课程',
      highlight: 0,
      side: 0
    }
  },
  // 优惠中心
  Discounts: {
    meta: {
      title: '优惠中心',
      highlight: 0,
      side: 1
    }
  },
  // 我的优惠券
  MyDiscounts: {
    meta: {
      title: '我的优惠券',
      highlight: 0,
      side: 2
    }
  },
  // 报名订单
  ApplyOrder: {
    meta: {
      title: '报名订单',
      highlight: 0,
      side: 3
    }
  },
  // 绑定账号
  Binding: {
    meta: {
      title: '绑定账号',
      highlight: 0,
      side: 4
    }
  },
  // 最近购买
  RecentPurchase: {
    meta: {
      title: '最近购买',
      highlight: 0,
      side: 5
    }
  },
  // 线上习题
  OnlineProblemSets: {
    meta: {
      title: '线上习题',
      highlight: 6,
      parent: [
        {
          title: '我的课程',
          url: 'Course.MyCourse'
        }
      ],
      side: 0
    }
  },
  // 线上习题
  BookOrderDetails: {
    meta: {
      title: '教材订单详情',
      highlight: -1,
      parent: [
        {
          title: '最近购买',
          url: 'Course.RecentPurchase'
        }
      ],
      side: 5
    }
  },
  // 系列课程
  SeriesCourses: {
    meta: {
      title: '系列课程',
      highlight: 6,
      parent: [
        {
          title: '我的课程',
          url: 'Course.MyCourse'
        }
      ],
      side: 0
    }
  }
}
