import { GetStorage } from '@/utils/Storage'
/**
 * 格式化api
 */
const ApiFormat = (format, info) => {
  const _format = format.split('/')
  let url = []
  _format.map(name => {
    info[name] && url.push(info[name])
  })
  return url.join('/')
}

/**
 * 初始化全局请求配置
 */
export const InitConfigInfo = (info) => {
  window.__request_config = info
}

/**
 * 设置全局请求配置
 */
export const SetConfigInfo = (key, value) => {
  window.__request_config[key] = value
}

/**
 * 获取全局请求配置
 */
export const GetConfigInfo = () => {
  return window.__request_config
}

/**
 * 根据apiName获取ModularInfo和ApiInfo
 */
export const GetApiInfo = apiName => {
  const { apiObj = {} } = GetConfigInfo()
  const cutApi = apiName.split('.')
  const modularKey = cutApi[0]
  const apiKey = cutApi[1]
  const Modular = apiObj[modularKey] || {}
  const ModularInfo = Modular.ModularInfo || {}
  const ActionList = Modular.ActionList || {}
  const ApiInfo = ActionList[apiKey] || {}
  return {
    ModularInfo, ApiInfo
  }
}

/**
 * 配置优先级获取
 * apiName
 * afferent - 第一优先
 * field - 字段
 * noValue - 都没有的情况下的值
 */
export const ConfigPriority = (apiName, afferent, field, noValue = '') => {
  const Config = GetConfigInfo()
  const { ModularInfo, ApiInfo } = GetApiInfo(apiName)
  if (typeof afferent !== 'undefined') {
    return afferent
  } else if (typeof ApiInfo[field] !== 'undefined') {
    return ApiInfo[field]
  } else if (typeof ModularInfo[field] !== 'undefined') {
    return ModularInfo[field]
  } else if (typeof Config[field] !== 'undefined') {
    return Config[field]
  } else {
    return noValue
  }
}

/**
 * 获取api
 */
export const GetApi = (apiName, option = {}) => {
  // 域名信息
  const hostUrl = ConfigPriority(apiName, option.hostUrl, 'hostUrl')
  // 端名信息
  const controlName = ConfigPriority(apiName, option.controlName, 'controlName')
  // 模块信息
  const modularName = ConfigPriority(apiName, option.modularName, 'modularName')
  // action信息
  const actionName = ConfigPriority(apiName, option.actionName, 'actionName')
  // api格式
  const apiFormat = ConfigPriority(apiName, option.apiFormat, 'apiFormat')
  return ApiFormat(apiFormat, {
    hostUrl,
    controlName,
    modularName,
    actionName
  })
}

/**
 * 获取请求头
 */
export const GetHeader = (apiName, header = {}) => {
  const Config = GetConfigInfo()
  const checkHead = (h, _h) => {
    if (h) {
      if (typeof h === 'object') {
        _h = { ..._h, ...h }
      } else if (typeof h === 'function') {
        _h = { ..._h, ...h() }
      }
    }
    return _h
  }
  const cheakToken = (token = {}, _h) => {
    let _token = {}
    for (let k in token) {
      _token[k] = GetStorage(token[k], 'local')
    }
    return { ..._h, ..._token }
  }
  const { ModularInfo, ApiInfo } = GetApiInfo(apiName)
  let _head = {}
  // 插入全局请求头
  _head = checkHead(Config.header, _head)
  // 插入模块请求头
  _head = checkHead(ModularInfo.header, _head)
  // 插入api请求头
  _head = checkHead(ApiInfo.header, _head)
  // 插入调用请求头
  _head = checkHead(header, _head)
  // 插入token
  _head = cheakToken(Config.token, _head)
  return _head
}

/**
 * 获取请求方式
 */
export const GetMethod = (apiName, method) => {
  let _method = ConfigPriority(apiName, method, 'method', 'GET')
  // 小写处理
  _method = _method.toLowerCase()
  switch (_method) {
    case 'get':
    case 'post':
      return _method
    default:
      return 'get'
  }
}

/**
 * 打开加载层
 */
export const OpenLoading = (apiName, showLoadingHandle) => {
  let { loadingStack } = GetConfigInfo()
  if (typeof loadingStack === 'undefined') {
    loadingStack = []
    SetConfigInfo('loadingStack', [])
  }
  const loadingNumer = loadingStack.filter(({ show }) => show)
  if (loadingNumer.length === 0) {
    const loadingFun = ConfigPriority(apiName, showLoadingHandle, 'showLoadingHandle')
    if (typeof loadingFun === 'function') {
      loadingFun()
    }
  }
  loadingStack.push({
    apiName,
    show: true
  })
  SetConfigInfo('loadingStack', loadingStack)
}

/**
 * 关闭加载层
 */
export const CloseLoading = (apiName, closeLoadingHandle) => {
  let { loadingStack } = GetConfigInfo()
  loadingStack = loadingStack.map(item => {
    let _item = { ...item }
    if (apiName === item.apiName) {
      _item.show = false
    }
    return _item
  })
  const loadingNumer = loadingStack.filter(({ show }) => show)
  if (loadingNumer.length === 0) {
    const loadingFun = ConfigPriority(apiName, closeLoadingHandle, 'closeLoadingHandle')
    if (typeof loadingFun === 'function') {
      loadingFun()
    }
  }
  SetConfigInfo('loadingStack', loadingStack)
}

/**
 * 检查是否在正确返回码内
 */
export const CheckSuccessCode = (apiName, res, successCode = []) => {
  const {
    ModularInfo: { successCode: _modularSuccessCode = [] },
    ApiInfo: { successCode: _apiSuccessCode = [] }
  } = GetApiInfo(apiName)
  const { successCode: _configSuccessCode = [] } = GetConfigInfo()
  const _successCode = [
    ..._configSuccessCode,
    ..._modularSuccessCode,
    ..._apiSuccessCode,
    ...successCode
  ]
  return _successCode.indexOf(Number(res.code)) >= 0
}

/**
 * 检查是否要忽略提示
 */
export const CheckIgnoreToastCode = (apiName, res, ignoreToastCode = []) => {
  const {
    ModularInfo: { ignoreToastCode: _modularIgnoreToastCode = [] },
    ApiInfo: { ignoreToastCode: _apiIgnoreToastCode = [] }
  } = GetApiInfo(apiName)
  const { ignoreToastCode: _configIgnoreToastCode = [] } = GetConfigInfo()
  const _ignoreToastCode = [
    ..._configIgnoreToastCode,
    ..._modularIgnoreToastCode,
    ..._apiIgnoreToastCode,
    ...ignoreToastCode
  ]
  return !(_ignoreToastCode.indexOf(Number(res.code)) >= 0)
}

/**
 * 数据解析处理
 */
export const ParseResData = (apiName, res, parseData) => {
  const parseDataFun = ConfigPriority(apiName, parseData, 'parseData')
  if (typeof parseDataFun === 'function') {
    return parseDataFun(res)
  } else {
    return res
  }
}

/**
 * 提示回调
 */
export const ToastMsg = (apiName, res, toastHandle) => {
  const toastFun = ConfigPriority(apiName, toastHandle, 'toastHandle')
  if (typeof toastFun === 'function') {
    return toastFun(res)
  }
}

export const HandleCode = (apiName, res, codeHandle) => {
  const Config = GetConfigInfo()
  const { ModularInfo, ApiInfo } = GetApiInfo(apiName)
  const checkCodeHandle = (h, _h) => {
    if (h && typeof h === 'object') {
      _h = { ..._h, ...h }
    }
    return _h
  }
  let handleObj = {}
  // 插入全局请求头
  handleObj = checkCodeHandle(Config.codeHandle, handleObj)
  // 插入模块请求头
  handleObj = checkCodeHandle(ModularInfo.codeHandle, handleObj)
  // 插入api请求头
  handleObj = checkCodeHandle(ApiInfo.codeHandle, handleObj)
  // 插入调用请求头
  handleObj = checkCodeHandle(codeHandle, handleObj)
  if (typeof handleObj[res.code] === 'function') {
    return handleObj[res.code](res)
  } else {
    return true
  }
}
