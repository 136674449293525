import routerEx from '@/router'
export const ModularList = [
  // 公共
  'Common',
  'Course',
  'Campus'
]

// router集成
let _router = {}
// vuex集成
let _store = {}
// api集成
let _api = {}

ModularList.map(modularName => {
  // 遍历收集store对象
  try {
    _store[modularName] = require(`./${modularName}/store`).default
  } catch (e) {}

  // 遍历收集router对象
  const routerItem = require(`./${modularName}/router`).default
  for (let pageName in routerItem) {
    _router[`${modularName}.${pageName}`] = routerItem[pageName]
  }

  // 遍历收集api对象
  try {
    const importInfo = require(`./${modularName}/api`)
    const ModularInfo = importInfo.ModularInfo || {}
    const ActionList = importInfo.default || {}
    _api[modularName] = {
      ModularInfo,
      ActionList
    }
  } catch (e) {}
})

export const router = _router
export const store = _store
export const api = _api

// 路由跳转封装
export const nav = (pageName, query = {}, openType = '') => {
  // 判断是否为后退
  if (pageName === 'back' || openType === 'navigateBack') {
    const delta = JSON.stringify(query) === JSON.stringify({}) ? 1 : Number(query)
    routerEx.go(delta * -1)
    return
  }

  const _query = _router[pageName].query || {}
  let routerInfo = pageName.split('.')
  const m = routerInfo[0]
  const p = routerInfo[1]

  const { type } = _router[pageName]
  // 打开类型，优先级为$nav->pageConfig.type
  const _openType = openType || type || 'navigateTo'

  switch (_openType) {
    case 'navigateTo':
    case 'switchTab':
    case 'reLaunch':
      routerEx.push({
        path: `/${m}/${p}`,
        query: {
          ..._query,
          ...query
        }
      })
      break
    case 'redirectTo':
      routerEx.replace({
        path: `/${m}/${p}`,
        query: {
          ..._query,
          ...query
        }
      })
      break
  }
}
