import Vue from 'vue'
import Router from 'vue-router'
import { router } from '@/pages'

Vue.use(Router)

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

let _routerList = []
let n = 0
for (let i in router) {
  let routerInfo = i.split('.')
  // 模块名
  const modularName = routerInfo[0]
  // 页面名
  const pageName = routerInfo[1]
  // 路由元信息
  let meta = router[i].meta || {}
  meta.pid = `${modularName}.${pageName}-${n}`

  _routerList.push({
    path: `/${modularName}/${pageName}`,
    name: `${modularName}.${pageName}`,
    component: resolve => require([`@/pages/${modularName}/page/${pageName}`], resolve),
    meta
  })
  n++
}

export default new Router({
  mode: 'history',
  routes: [
    { path: '*', redirect: '/Common/Home' },
    ..._routerList
  ]
})
