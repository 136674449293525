import HeadBar from './HeadBar'
import Footer from './Footer'
import DiyTitle from './DiyTitle'
import Paging from './Paging'
import Tab from './Tab'
import TiliteNav from './TiliteNav'

export default {
  install: Vue => {
    Vue.component('HeadBar', HeadBar)
    Vue.component('Footer', Footer)
    Vue.component('DiyTitle', DiyTitle)
    Vue.component('Paging', Paging)
    Vue.component('Tab', Tab)
    Vue.component('TiliteNav', TiliteNav)
  }
}
