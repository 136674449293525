export const ModularInfo = {
	modularName: 'Public'
}

export default {
	// 首页 /Pc/News/indexV2
	getIndex: {
		modularName: 'News',
		actionName: 'indexV2'
	},
	// 导航列表 /Pc/News/getArticleListV2
	getArticleListV2: {
		modularName: 'News',
		actionName: 'getArticleListV2'
	},
	// 资讯详情  (v2) /Pc/News/getArticleListV2
	getArticleDetailsV2: {
		modularName: 'News',
		actionName: 'getArticleDetailsV2'
	},
	// 试看视频 /User/Goods/videoList
	videoList: {
		controlName: 'User',
		modularName: 'Goods',
		actionName: 'videoList'
	},
	// 系列课程 /User/Goods/goodsList
	goodsList: {
		controlName: 'User',
		modularName: 'Goods',
		actionName: 'goodsList'
	},
	// 线上习题 /User/Paper/alonePaperList
	alonePaperList: {
		controlName: 'User',
		modularName: 'Paper',
		actionName: 'alonePaperList'
	},
	// 获取登录二维码 /User/Public/getLoginCode
	getLoginCode: {
		controlName: 'User',
		actionName: 'getLoginCode'
	},
	// 获取登录二维码状态（长轮训）User/Public/getWxLoginCodeByFind
	getWxLoginCodeByFind: {
		controlName: 'User',
		actionName: 'getWxLoginCodeByFind'
	},
	// 推荐试看列表 Pc/News/tryListV2
	tryList: {
		controlName: 'Pc',
		modularName: 'News',
		actionName: 'tryListV2'
	},
	// 推荐购买列表 Pc/News/purchaseListV2
	purchaseList: {
		controlName: 'Pc',
		modularName: 'News',
		actionName: 'purchaseListV2'
	},
	// 推荐习题列表 Pc/News/paperListV2
	paperList: {
		controlName: 'Pc',
		modularName: 'News',
		actionName: 'paperListV2'
	},
	// 商品详情 Pc/Goods/goodsDetails
	goodsDetails: {
		controlName: 'Pc',
		modularName: 'Goods',
		actionName: 'goodsDetailsV2'
	},
	// 确认订单(线下) /Pc/OfflineOrder/confirmOrderV2
	OfflineConfirmOrder: {
		controlName: 'Pc',
		modularName: 'OfflineOrder',
		actionName: 'confirmOrderV2'
	},
	// 创建订单(线下) /Pc/OfflineOrder/createOrderV2
	OfflineCreateOrder: {
		controlName: 'Pc',
		modularName: 'OfflineOrder',
		actionName: 'createOrderV2'
	},
	// 确认订单(正常) /User/Order/confirmOrder
	confirmOrder: {
		controlName: 'User',
		modularName: 'Order',
		actionName: 'confirmOrder'
	},
	// 创建订单(正常) /User/Order/createOrder
	createOrder: {
		controlName: 'User',
		modularName: 'Order',
		actionName: 'createOrder'
	},
	// 城市列表 （v2） /Pc/OfflineGoods/getCityListV2
	getCityList: {
		controlName: 'Pc',
		modularName: 'OfflineGoods',
		actionName: 'getCityListV2'
	},
	// 线下报名详情 /Pc/OfflineGoods/offlineGoodsDetailsV2
	offlineGoodsDetails: {
		controlName: 'Pc',
		modularName: 'OfflineGoods',
		actionName: 'offlineGoodsDetailsV2'
	},
	//  线下报名列表 /Pc/OfflineGoods/offlineGoodsListV2
	offlineGoodsList: {
		controlName: 'Pc',
		modularName: 'OfflineGoods',
		actionName: 'offlineGoodsListV2'
	},
	// 联系我们
	releaseOpinion: {
		controlName: 'User',
		modularName: 'Index',
		actionName: 'releaseOpinion'
	},
	// 个人中心 User/Index/userCenter
	userCenter: {
		controlName: 'User',
		modularName: 'Index',
		actionName: 'userCenter'
	},
	// 协议详情 User/Public/getAgreement
	getAgreement: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'getAgreement'
	},
	// 微信扫码支付 （正常订单） /User/Order/getWebPay
	getWebPay: {
		controlName: 'User',
		modularName: 'Order',
		actionName: 'getWebPay'
	},
	// 支付宝支付（正常订单） /User/Order/getAlipay
	getAlipay: {
		controlName: 'User',
		modularName: 'Order',
		actionName: 'getAlipay'
	},
	// 支付宝支付（线下订单） /User/OfflineOrder/getAlipay
	OfflineAlipay: {
		controlName: 'User',
		modularName: 'OfflineOrder',
		actionName: 'getAlipay'
	},
	// 微信扫码支付 （线下订单） /User/OfflineOrder/getWebPay
	OfflineWebPay: {
		controlName: 'User',
		modularName: 'OfflineOrder',
		actionName: 'getWebPay'
	},
	// 订单详情 （正常订单） /User/Order/orderDetails
	orderDetails: {
		controlName: 'User',
		modularName: 'Order',
		actionName: 'orderDetails'
	},
	// 订单详情 （线下订单） /User/OfflineOrder/orderDetails
	OfflineOrderDetails: {
		controlName: 'User',
		modularName: 'OfflineOrder',
		actionName: 'orderDetails'
	},
	// 获取题目
	getPaperDetails: {
		actionName: 'getPaperDetails',
		controlName: 'User',
		modularName: 'Paper'
	},
	// 创建试卷
	createPaper: {
		actionName: 'createPaper',
		controlName: 'User',
		modularName: 'Paper'
	},
	// 提交试卷
	submitPaper: {
		actionName: 'submitPaper',
		controlName: 'User',
		modularName: 'Paper'
	},
	// 查看试卷成绩 User/Paper/getSubmitPaperDetails
	getSubmitPaperDetails: {
		actionName: 'getSubmitPaperDetails',
		controlName: 'User',
		modularName: 'Paper'
	},
	// 视频播放记录，点播播放的时候请求一次记录播放量 (v2) Pc/Goods/playVideoLogV2
	playVideoLog: {
		controlName: 'Pc',
		modularName: 'Goods',
		actionName: 'playVideoLogV2'
	},
	// 开始记录 User/Goods/startVideoViewLog
	startVideoViewLog: {
		controlName: 'User',
		modularName: 'Goods',
		actionName: 'startVideoViewLog'
	},
	// 观看结束 User/Goods/endVideoViewLog
	endVideoViewLog: {
		controlName: 'User',
		modularName: 'Goods',
		actionName: 'endVideoViewLog'
	},
	// 获取三级城市接口 User/Public/allRegion
	allRegion: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'allRegion'
	},
	// 获取登录token /User/Public/getQrcodeToken
	getQrcodeToken: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'getQrcodeToken'
	},
	// 获取登录二维码 /User/Public/createQrcode
	createQrcode: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'createQrcode'
	},
	// 点播内容分类
	broadcastGroup: {
		controlName: 'Pc',
		modularName: 'Broadcast',
		actionName: 'broadcastGroup'
	},
	// 点播详情
	broadcast: {
		controlName: 'Pc',
		modularName: 'Broadcast',
		actionName: 'broadcast'
	},
	// 商品详情（跟读字幕）
	goodsDetailsV2: {
		controlName: 'Pc',
		modularName: 'Goods',
		actionName: 'goodsDetailsV2'
	},
	// 获取优秀学生列表
	getStudentList: {
		controlName: 'Pc',
		modularName: 'Index',
		actionName: 'getStudentList'
	},
	// 获取学生成绩列表
	getAchievementList: {
		controlName: 'Pc',
		modularName: 'Index',
		actionName: 'getAchievementList'
	},
	// 推荐购买列表
	purchaseListV2: {
		controlName: 'Pc',
		modularName: 'News',
		actionName: 'purchaseListV2'
	},
	// 导航列表,school_campus：校园介绍，school_news：校园资讯
	getArticleListV2: {
		controlName: 'Pc',
		modularName: 'News',
		actionName: 'getArticleListV2'
	},
	// 获取研究院图片的显示
	getResearchPicture: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'getResearchPicture'
	},
	// 获取师资力量背景
	getTeachersBackground: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'getTeachersBackground'
	},
	// 获取轮播图详情
	getBannerDetails: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'getBannerDetails'
	},
	// 获取学院介绍图片
	getSchoolAbout: {
		controlName: 'User',
		modularName: 'Public',
		actionName: 'getSchoolAbout'
	}
}
