import { GetRndStringNum } from '@/utils/Tool'
import { Loading, Message, MessageBox } from 'element-ui'

var loadingInstance

/**
 * 打开加载层
 */
export const ShowLoading = (title = '加载中', { mask = true } = {}) => {
  return new Promise((resolve, reject) => {
    // window.layer.load()
    loadingInstance = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  })
}

/**
 * 关闭加载层
 */
export const CloseLoading = () => {
  return new Promise((resolve, reject) => {
    // window.layer.closeAll()
    loadingInstance.close()
  })
}

/**
 * 打开消息提示框
 */
export const ShowToast = (
  title = '提示',
  { icon = 'none', image = '', duration = 1500, mask = false } = {}
) => {
  return new Promise((resolve, reject) => {
    let _icon = ''
    if (icon === 'success') {
      _icon = 1
    } else if (icon === 'fail') {
      _icon = 5
    }
    window.layer.msg(title, {
      icon: _icon,
      time: duration,
      shade: mask ? 0.3 : false
    })
  })
}

/**
 * 关闭消息提示框
 */
export const CloseToast = () => {
  return new Promise((resolve, reject) => {
    window.layer.closeAll()
  })
}

/**
 * 打开询问框
 */
export const ShowConfirm = (
  content = '内容',
  {
    title = '提示',
    showCancel = true,
    cancelText = '取消',
    cancelColor = '#000000',
    confirmText = '确定',
    confirmColor = '#576B95'
  } = {}
) => {
  let btn = [confirmText]
  if (showCancel) {
    btn.push(cancelText)
  }
  return new Promise((resolve, reject) => {
    window.layer.confirm(
      content,
      { icon: 3, title, btn },
      function (index) {
        resolve('confirm')
        window.layer.close(index)
      }
    )
  })
}

/**
 * 浏览图片
 */
export const PreviewImage = (list = [], index = 0) => {
  return new Promise((resolve, reject) => {
    window.layer.photos({
      photos: {
        title: '浏览图片',
        id: GetRndStringNum(),
        start: index,
        data: list.map(item => ({
          alt: '',
          pid: GetRndStringNum(),
          src: item,
          thumb: item
        }))
      },
      anim: 5
    })
  })
}

// 消息提醒
export const ShowMessage = ({
  msg = '',
  type = '',
  showClose = true,
  duration = 2000
} = {}) => {
  // type : success、warning、error
  Message({
    showClose: showClose,
    message: msg,
    type: type,
    duration: duration
  })
}

export default {
  install: Vue => {
    Vue.prototype.$ShowToast = ShowToast
    Vue.prototype.$ShowLoading = ShowLoading
    Vue.prototype.$CloseLoading = CloseLoading
    Vue.prototype.$CloseToast = CloseToast
    Vue.prototype.$ShowConfirm = ShowConfirm
    Vue.prototype.$PreviewImage = PreviewImage
    // Vue.prototype.$Message = ShowMessage
    Vue.prototype.$Message = Message
    Vue.prototype.$MessageBox = MessageBox
  },
  ShowToast,
  ShowLoading,
  CloseLoading,
  CloseToast,
  ShowConfirm,
  PreviewImage,
  Message,
  MessageBox
}
