<template>
  <div id="app">
    <!-- 顶部导航栏 -->
    <HeadBar v-show="headShow" />
    <div class="app-box">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <!-- 底部信息 -->
    <Footer v-show="footerShow" />
    <!-- 报名 -->
    <OfflinePay />
    <!-- 购买 -->
    <Pay />
    <BookPay />
    <div class="side-fixed">
      <div class="side-fixed-item flex-ccc item1" @click="consultKefu">
        <div class="img"></div>
        <div class="side-text">咨询客服</div>
      </div>
      <div class="side-fixed-item flex-ccc item2" @click="goScrollTop">
        <img :src="$img('IconScrolltop')" class="item-img">
        <div class="side-text">回到顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import Pay from '@/components/pay'
  import OfflinePay from '@/components/pay/OfflinePay'
  import BookPay from '@/components/pay/book'
  export default {
    name: 'app',
    data () {
      return {
        title: '现代日语',
        headShow: false,
        footerShow: true,
        process: null
      }
    },
    provide () {
      return {
        app: this
      }
    },
    mounted () {
      this.pageInit()
      if (this.isLogin) {
        this.userCenter()
      }
      this.continueProcess()

      // console.log('暂时写死ZTBTOKEN-1-记得注释')
      // this.$SetStorage('ZTBTOKEN', '9bdcm/rYRcMj4mv4zxyGu11H1Z7irXyWv8pRgIZU', 'local')
    },
    methods: {
      // 继续未登录操作
      async continueProcess () {
        this.process = this.$GetStorage('process')
        if (this.process && this.isLogin) {
          switch (this.process.key) {
          case 'Pay':
            this.onlinePayProocess()
            break
          case 'bookPay':
            this.$Bus.$emit(this.process.key, this.process.data)
            break
          case 'OfflinePay':
            this.$Bus.$emit(this.process.key, this.process.data)
            break
          case 'releaseOpinion':
            this.$Bus.$emit(this.process.key, this.process.data)
            break
          }
          this.$SetStorage('process', '')
        }
      },
      async onlinePayProocess () {
        let { status, data } = await this.$Get('Common.goodsDetails', {
          id: this.process.data.id
        })
        if (status && data.goods.my_purchase * 1 === 0) {
          this.$Bus.$emit(this.process.key, this.process.data)
        }
      },
      async releaseOpinion () {
        let { status } = await this.$Post('Common.releaseOpinion', this.process.data)
        if (status) {
          this.$ShowToast('提交成功', { icon: 'success' })
        }
      },
      pageInit () {
        $(document).scrollTop(0)
        if (this.$route.meta) {
          // 阻止切换动画
          this.transition_name = this.$route.meta.isNotAnimation ? '' : 'slide-fade'
        }
        // 显示页面标题
        document.title = this.$route.meta.title || this.title
        this.headShow = !this.$route.meta.hiddenHead
        this.footerShow = this.$route.meta.footerShow !== 'hide'
        this.SaveHighlight(this.$route.meta.highlight)
      },
      goScrollTop () {
        $(document).scrollTop(0)
      },
      consultKefu () {
        $('.Lelem').trigger('click')
        $('#mobile_icon_div div').trigger('click')
      },
      ...mapMutations('Common', ['SaveHighlight']),
      ...mapActions('Common', ['userCenter'])
    },
    watch: {
      $route (to) {
        this.pageInit()
      }
    },
    computed: {
      ...mapState('Common', ['isLogin'])
    },
    components: {
      Pay,
      OfflinePay,
      BookPay
    }
  }
</script>

<style lang="less">

.Lelem, #mobile_icon_div div {
  position: fixed !important;
  bottom: 260px !important;
  right: 30px !important;
  left: auto !important;
  top: auto !important;
  width: 100px !important;
  height: 100px !important;
  opacity: 0;
  z-index: -1 !important;
  display: none;
  img {
    width: 100px !important;
    height: 100px !important;
  }
}
.app-box {
  min-height: 600px;
}
body {
  /deep/ .el-select-dropdown {
    z-index: 999999 !important;
  }
}
.bouttom-end {
  padding: 0.16rem 0.24rem;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}
.black-bg {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99998 !important;
}
.min-page {
  min-width: 1200px;
}
.page {
  width: 1200px;
  position: relative;
}
.van-popup {
  background-color: transparent !important;
  z-index: 99999 !important;
}
.el-input input {
  height: 54px;
  font-size: 16px;
  border-radius: 10px;
  font-weight: normal;
}
.el-select-dropdown,
.el-popper {
  z-index: 99999 !important;
}
.el-select,
.el-cascader {
  display: block !important;
}
#app {
  /deep/ .van-popup {
    background-color: transparent;
    z-index: 99999 !important;
  }
}
@keyframes movefromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes movefromLeft2 {
  from {
    opacity: 0;
    transform: translateX(-200%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes movefromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes movefromRight2 {
  from {
    opacity: 0;
    transform: translateX(200%);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes movefromTop {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes movefromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.animation {
  &:nth-child(n) {
    animation: movefromLeft2 2.5s;
    animation-fill-mode: forwards;
  }
  &:nth-child(2n) {
    animation: movefromLeft2 2s;
    animation-fill-mode: forwards;
  }
  &:nth-child(3n) {
    animation: movefromRight2 2s;
    animation-fill-mode: forwards;
  }
  &:nth-child(4n) {
    animation: movefromRight2 2.5s;
    animation-fill-mode: forwards;
  }
}
.popup-box {
  width: 680px;
  height: 769px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 24px 60px 0 50px;
  z-index: 9999999;
  .popup-title {
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    color: #333333;
    text-align: center;
    margin-bottom: 36px;
  }
  .popup-line {
    margin-bottom: 36px;
    .flex();
    .popup-label {
      width: 80px;
      font-size: 16px;
      margin-right: 16px;
      line-height: 24px;
      color: #888888;
      text-align: right;
      span {
        color: red;
      }
    }
    .popup-text {
      .flex-1();
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
      &.text1 {
        font-size: 20px;
        font-family: WeChatNumber-151125;
        font-weight: 500;
        line-height: 24px;
        color: #ca0000;
        span {
          font-size: 12px;
        }
      }
    }
    .popup-select {
      width: 160px;
      height: 60px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      margin-right: 30px;
      border-radius: 10px;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      position: relative;
      cursor: pointer;
      .flex-cc();
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
      .checked {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 0;
        top: 0;
        margin-right: 0;
      }
      &.active {
        border-color: #ca0000;
      }
    }
  }
  .agreement {
    margin-top: 80px;
    font-size: 14px;
    line-height: 24px;
    color: #b2b2b2;
    margin-bottom: 25px;
    cursor: pointer;
    span {
      color: #6a8ebc;
    }
    img {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
  .popup-content {
    img {
      width: 200px;
      height: 112px;
      border-radius: 10px;
      margin-right: 24px;
    }
    .popup-line-title {
      font-size: 24px;
      color: #333333;
      margin-bottom: 10px;
    }
    .popup-line-desc {
      font-size: 18px;
      line-height: 24px;
      color: #b2b2b2;
    }
  }
  .popup-btn {
    width: 180px;
    height: 50px;
    background: #ca0000;
    box-shadow: 0px 6px 8px rgba(202, 0, 0, 0.2);
    opacity: 1;
    border-radius: 25px;
    font-size: 18px;
    color: #fff;
    margin: auto;
    cursor: pointer;
    .flex-cc();
  }
  /deep/ .el-input input {
    height: 54px;
    font-size: 16px;
    border-radius: 10px;
    font-weight: normal;
  }
  /deep/ .el-select {
    display: block;
  }
}
.side-fixed {
  position: fixed;
  bottom: 160px;
  right: 30px;
  z-index: 10002;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
  .side-fixed-item {
    width: 100px;
    height: 100px;
    font-size: 14px;
    line-height: 25px;
    cursor: pointer;
    color: #888;
    background: #fff;
    img,
    .img {
      width: 50px;
      height: 50px;
    }
    .img {
      background: url('/static/images/icon/kefu2.png');
    }
    &.item1 {
      color: #ca0000;
      &:hover {
        color: #ffffff;
        background: #ca0000;
        .img {
          background: url('/static/images/icon/kefu1.png');
        }
      }
    }
    &.item2 {
      border-top: 1px solid #e5e5e5;
    }
  }
}
</style>
