const h = window.location.protocol + '//' + window.location.host + '/'
const CommonUrl = h + 'static/images/'

export const Image = {
  Logo: 'logo.png',
  IconAddress: 'icon/address.png',
  IconClose: 'icon/close.png',
  IconAddressTwo: 'icon/address2.png',
  IconPhone: 'icon/phone.png',
  IconPhoneTwo: 'icon/phone2.png',
  IconEmail: 'icon/email.png',
  IconRightThree: 'icon/right-three.png',
  IconRightFour: 'icon/right-four.png',
  IconStar: 'icon/star.png',
  IconBtnOne: 'icon/btn1.png',
  IconBtnTwo: 'icon/btn2.png',
  IconDiscountsOne: 'icon/discounts1.png',
  IconDiscountsTwo: 'icon/discounts2.png',
  IconKefu: 'icon/kefu1.png',
  IconScrolltop: 'icon/scrolltop.png',
  IconToast: 'icon/toast.png',
  IconToastOne: 'icon/toast1.png',
  IconSelectOne: 'icon/select1.png',
  IconSelectTwo: 'icon/select2.png',
  IconSearch: 'icon/search.png',
  IconChecked: 'icon/checked.png',
  IconCheckedFill: 'icon/checked_fill.png',
  IconCheckedFillTwo: 'icon/checked_fill2.png',
  IconWx: 'icon/wx.png',
  IconZfb: 'icon/zfb.png',
  IconPlay: 'icon/play.png',
  IconPlayTwo: 'icon/play2.png',
  IconPlayThree: 'icon/play3.png',
  IconPause: 'icon/pause.png',
  IconRebroadcast: 'icon/rebroadcast.png',
  IconAnswer: 'icon/answer.png',
  IconTopOne: 'icon/top1.png',
  IconTopTwo: 'icon/top2.png',
  IconLabel: 'icon/label.png',
  Code: 'entrepot/code.png',
  Code2: 'entrepot/code2.jpg',
  CodeBg: 'entrepot/codebg.png',
  FigureBgOne: 'entrepot/figurebg1.png',
  FigureBgTwo: 'entrepot/figurebg2.png',
  FigureBgThree: 'entrepot/figurebg3.png',
  Masking: 'entrepot/masking.png'
}

const Size = {
  '100x100': 'w=640&height=500'
}

export const GetImage = (key, sizeItem = '') => {
  return `${CommonUrl}${Image[key]}${sizeItem ? Size[sizeItem] : ''}`
}

export const SetImage = (imgList = [], { bundle = false, bundleName = 'Images' } = {}) => {
  let _r = {}
  if (bundle) {
    _r[bundleName] = () => {
      let _I = {}
      imgList.map(name => {
        const cutName = name.split('_')
        const imgSize = cutName[1] ? Size[cutName[1]] || '' : ''
        const imgUrl = Image[cutName[0]] || ''
        _I[name] = `${CommonUrl}${imgUrl}${imgSize ? '?' : ''}${imgSize}`
      })
      return _I
    }
  } else {
    imgList.map(name => {
      const cutName = name.split('_')
      const imgSize = cutName[1] ? Size[cutName[1]] || '' : ''
      const imgUrl = Image[cutName[0]] || ''
      _r[name] = () => {
        return `${CommonUrl}${imgUrl}${imgSize ? '?' : ''}${imgSize}`
      }
    })
  }
  return _r
}

export default SetImage
