<template>
  <div class="cutomtab">
    <div v-for="(item, index) in list" :key="index" @click="clickTab(index)" class="tab-item" :class="{active: index === value}">{{item.name}}</div>
  </div>
</template>

<script>
  export default {
    props: {
      list: [Array],
      value: null
    },
    methods: {
      clickTab (index) {
        this.$emit('input', index)
      }
    }
  }
</script>

<style lang="less" scoped>
  .cutomtab {
    .flex-c();
    .tab-item {
      .flex-cc();
      width: 204px;
      height: 52px;
      border-radius: 26px;
      font-size: 20px;
      color: #B2B2B2;
      background: transparent;
      cursor: pointer;
      white-space: nowrap;
      &.active {
        color: #fff;
        background: #CA0000;
        box-shadow: 0px 16px 20px rgba(202, 0, 0, 0.2);
      }
    }
  }
</style>
