import { GetStorage } from '@/utils/Storage'
const token = GetStorage('ZTBTOKEN', 'local')

export default {
  isLogin: !!token,
  user_info: {},
  user_order: {},
  highlight: 0,
  sign: ''
}
