export const ModularInfo = {
  modularName: 'Public'
}

export default {
  // 我的课程 (v2) /Pc/News/orderRecentlyV2
  orderRecently: {
    controlName: 'Pc',
    modularName: 'News',
    actionName: 'orderRecentlyV2'
  },
  // 系列课程列表 (v2) /Pc/News/packageDetailsV2
  packageDetails: {
    controlName: 'Pc',
    modularName: 'News',
    actionName: 'packageDetailsV2'
  },
  // 可领优惠卷列表 (v2) /Pc/Coupon/couponListV2
  couponList: {
    controlName: 'Pc',
    modularName: 'Coupon',
    actionName: 'couponListV2'
  },
  // 领取普通优惠卷 (v2) /Pc/Coupon/getOrdinaryCouponV2
  getOrdinaryCoupon: {
    controlName: 'Pc',
    modularName: 'Coupon',
    actionName: 'getOrdinaryCouponV2'
  },
  // 通过优惠码领取优惠卷 (v2) /Pc/Coupon/getDrawCouponV2
  getDrawCoupon: {
    controlName: 'Pc',
    modularName: 'Coupon',
    actionName: 'getDrawCouponV2'
  },
  // 我的优惠卷 (v2) /Pc/Coupon/myCouponListV2
  myCouponList: {
    controlName: 'Pc',
    modularName: 'Coupon',
    actionName: 'myCouponListV2'
  },
  // 报名订单 (v2) /Pc/OfflineOrder/orderListV2
  orderList: {
    controlName: 'Pc',
    modularName: 'OfflineOrder',
    actionName: 'orderListV2'
  },
  // 绑定手机号 (v2) /Pc/Index/bindingPhoneV2
  bindingPhone: {
    controlName: 'Pc',
    modularName: 'Index',
    actionName: 'bindingPhoneV2'
  },
  // 获取手机号的绑定  (v2) /Pc/Index/getBindingPhoneV2
  getBindingPhone: {
    controlName: 'Pc',
    modularName: 'Index',
    actionName: 'getBindingPhoneV2'
  },
  // 解绑用户手机号 (v2) /Pc/Index/unbindPhoneV2
  unbindPhone: {
    controlName: 'Pc',
    modularName: 'Index',
    actionName: 'unbindPhoneV2'
  },
  // 发送短信验证码 (v2) /Pc/Index/sendVerificationV2
  sendVerification: {
    controlName: 'Pc',
    modularName: 'Index',
    actionName: 'sendVerificationV2'
  },
  // 最近购买 /User/Order/orderList2
  orderList2: {
    controlName: 'User',
    modularName: 'Order',
    actionName: 'orderList2'
  },
  // 后台分配套餐
  adminDistributionList: {
    controlName: 'Pc',
    modularName: 'News',
    actionName: 'adminDistributionList'
  },
}
