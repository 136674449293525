import dayjs from 'dayjs'
import dayjszh from 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.locale(dayjszh)

/**
 * 生成ID
 * @return {string} id
 */
export const GetRndStringNum = (number = 8) => {
  const str = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  let id = ''
  for (let i = 0; i < number; i++) {
    id += str.charAt(Math.floor(Math.random() * str.length))
  }
  return id
}

/**
 * 时间格式转换
 */
export const FormatTime = (time = '', formatString = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(time).format(formatString)
}

/**
 * 时间格式转换
 */
export const Diff = (time = '') => {
  return dayjs(dayjs(time)).diff(new Date(), 'day')
}

/**
 * 日期判断是否是当天(参数为毫秒)
 */
export const isSameDay = (timeStampA, timeStampB) => {
  let dateA = new Date(timeStampA)
  let dateB = new Date(timeStampB)
  return dateA.setHours(0, 0, 0, 0) === dateB.setHours(0, 0, 0, 0)
}

/**
 * 时间戳转日期
 */
export const timestampToTime = timestamp => {
  var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var y = date.getFullYear()
  var m = (date.getMonth() + 1).toString().padStart(2, '0')
  var d = date
    .getDate()
    .toString()
    .padStart(2, '0')
  var h = date
    .getHours()
    .toString()
    .padStart(2, '0')
  var mm = date
    .getMinutes()
    .toString()
    .padStart(2, '0')
  var s = date
    .getSeconds()
    .toString()
    .padStart(2, '0')
  return {
    y,
    m,
    d,
    h,
    mm,
    s
  }
}

// 返回1-n 之间的数字，例如n=100，返回1-100的数字
export const randomNumber = n => {
  return Math.floor(Math.random() * (n - 1 + 1) + 1)
}

// 获取文件后缀
export const getFileFormat = str => {
  var pattern = /\.{1}[a-z]{1,}$/
  var s = pattern.exec(str)
  if (s !== null) {
    return s[0].split('.')[1]
  } else {
    return str
  }
}

// IE浏览器判断
export const IEVersion = () => {
  var userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  var isIE =
    userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
  var isEdge = userAgent.indexOf('Edge') > -1 && !isIE // 判断是否IE的Edge浏览器
  var isIE11 =
    userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE) {
    var reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    var fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion === 7) {
      return 7
    } else if (fIEVersion === 8) {
      return 8
    } else if (fIEVersion === 9) {
      return 9
    } else if (fIEVersion === 10) {
      return 10
    } else {
      return 6 // IE版本<=7
    }
  } else if (isEdge) {
    return 'edge' // edge
  } else if (isIE11) {
    return 11 // IE11
  } else {
    return -1 // 不是ie浏览器
  }
}
