<template>
  <div class="flex-c paging" v-show="items > 0">
    <div class="paging-btn" @click="$emit('input', 1)">首页</div>
    <div class="paging-btn" @click="clickPev">上一页</div>
    <div class="block">
      <el-pagination
        layout="pager"
        :current-page="value"
        @current-change="currentChange"
        :page-size="limit"
        :total="items">
      </el-pagination>
    </div>
    <div class="paging-btn" @click="clickNext">下一页</div>
    <div class="paging-btn" @click="$emit('input', total)">尾页</div>
    <div class="total">共{{total}}页</div>
  </div>
</template>

<script>
  export default {
    name: 'paging',
    props: {
      total: [Number],
      value: [Number],
      limit: [Number],
      items: [Number]
    },
    methods: {
      clickPev () {
        if (this.value > 1) {
          this.$emit('input', this.value - 1)
        }
      },
      clickNext () {
        if (this.total > this.value) {
          this.$emit('input', this.value + 1)
        }
      },
      currentChange (val) {
        this.$emit('input', val)
      }
    }
  }
</script>

<style lang="less" scoped>
  .paging {
    .paging-btn {
      height: 30px;
      .flex-cc();
      background: #F0F0F0;
      padding: 0 24px;
      color: #888;
      font-size: 14px;
      margin-right: 12px;
      border-radius: 30px;
      cursor: pointer;
      &:hover {
        color: #fff;
        background: #CA0000;
      }
    }
    /deep/ .el-pager {
      display: flex;
      .number {
        height: 30px;
        .flex-cc();
        background: #F0F0F0;
        padding: 0 24px;
        color: #888;
        font-size: 14px;
        margin-right: 12px;
        border-radius: 30px;
        &:hover {
          color: #fff;
          background: #CA0000;
        }
      }
      .active, .active:hover {
        color: #fff;
        background: #333;
      }
    }
    /deep/ .el-icon-more,
    /deep/ .el-icon-d-arrow-right,
    /deep/ .el-icon-d-arrow-left{
      background-color: transparent;
      margin-right: 12px;
      color: #333;
    }
    .total {
      height: 30px;
      .flex-cc();
      font-size: 12px;
      line-height: 25px;
      color: #B2B2B2;
      margin-left: 16px;
    }
  }
</style>
