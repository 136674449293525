import '@babel/polyfill'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets'
import Bus from '@/utils/Bus'
import * as Tool from '@/utils/Tool'
import * as CodeHandle from '@/utils/CodeHandle'
import * as filters from '@/utils/Filters'
import Config from '@/utils/Config'
import Interactive from '@/utils/Interactive'
import Request from '@/utils/Request'
import { api, nav } from '@/pages'
import '@/plugins/element-ui'
import '@/plugins/swiper'
import '@/plugins/vant'
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import { Image, GetImage } from '@/utils/Image'
import Storage from '@/utils/Storage'
import Clg from '../package.json'
import Custom from '@/components'
// import VideoPlayer from 'vue-video-player'
Vue.use(animated)

console.log('version', Clg.version)

// Vue.use(VideoPlayer)

// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')

// 自定义全局组件
Vue.use(Custom)
// 引入请求方法
Vue.use(Request, {
  apiObj: api,
  ...Config,
  ...CodeHandle
})
// 引入缓存
Vue.use(Storage)
// 引入图片注册
Vue.prototype.$Image = Image
Vue.prototype.$img = GetImage
// window.SetImage = SetImage
// 引入全局广播器
Vue.use(Bus)
// 引入工具函数
Vue.prototype.$Tool = Tool
// 引入全局配置
Vue.prototype.$Config = Config
// 引入交互方法
Vue.use(Interactive)
// 引入路由跳转方法
Vue.prototype.$nav = nav
// 引入过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
