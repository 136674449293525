<template>
  <div class="flex-ccc" ref="diytitle">
    <div class="diy-title" :class="{'animation': animation && isanimation}">{{ title }}</div>
    <div class="diy-note" :class="{'animation': animation && isanimation}">{{ note }}</div>
  </div>
</template>

<script>
  export default {
    name: 'diy-title',
    props: {
      title: [String],
      note: [String],
      animation: [Boolean],
      defkey: null,
      scrollToTop: {
        type: Function,
        default: () => {}
      }
    },
    watch: {
      animation (to) {
        if (to) {
          setTimeout(() => {
            this.isanimation = false
          }, 2100)
        }
      }
    },
    data () {
      return {
        isanimation: true
      }
    },
    mounted () {
      window.addEventListener('scroll', () => { this.scrollToTop(this, 'diytitle', this.defkey) }, true)
      if (this.animation) {
        setTimeout(() => {
          this.isanimation = false
        }, 2100)
      }
    }
  }
</script>

<style lang="less" scoped>
  .diy-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 46px;
    color: #333333;
    margin-bottom: 24px;
    position: relative;
    z-index: 3;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 12px;
      background: linear-gradient(90deg, #CA0000 0%, rgba(202, 0, 0, 0) 100%);
      opacity: 1;
      border-radius: 6px;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
    &.animation{
      animation: movefromLeft 2s;
      animation-fill-mode: forwards;
    }
  }
  .diy-note {
    height: 26px;
    font-size: 20px;
    line-height: 25px;
    color: #B2B2B2;
    &.animation{
      animation: movefromRight 2s;
      animation-fill-mode: forwards;
    }
  }
</style>
