import {
  ConfigPriority,
  GetApi,
  GetHeader,
  GetMethod,
  OpenLoading,
  CloseLoading,
  CheckSuccessCode,
  CheckIgnoreToastCode,
  ParseResData,
  ToastMsg,
  HandleCode
} from './lib'
import Axios from 'axios'
import qs from 'qs'

export const Request = (apiName, data = {}, {
  apiFormat, loading, header = {}, method,
  actionName, modularName, controlName, hostUrl,
  successCode = [], ignoreToastCode = [], forceReturn, parseData,
  showLoadingHandle, closeLoadingHandle, catchRequestHandle, toastHandle, codeHandle
} = {}) => {
  return new Promise((resolve, reject) => {
    // api地址
    const apiUrl = GetApi(apiName, { actionName, modularName, controlName, hostUrl })
    // 请求头
    const _header = GetHeader(apiName, header)
    // 请求方式
    const _method = GetMethod(apiName, method)
    // 判断请求方式
    let requestFun = ''
    if (_method === 'get') {
      requestFun = Get
    } else if (_method === 'post') {
      requestFun = Post
    }
    // 打开loadig加载
    ConfigPriority(apiName, loading, 'loading', true) && OpenLoading(apiName, showLoadingHandle)
    // 执行请求体
    const option = {
      url: apiUrl,
      data,
      header: _header,
      // header: {ZTBTOKEN:'9bdcm/rYRcMj4mv4zxyGu11H1Z7irXyWv8pRgIZU'}
    }

    // console.log('暂时写死ZTBTOKEN-2-记得注释')
    requestFun && requestFun(option).then(res => {
      // 关闭loadig加载
      ConfigPriority(apiName, loading, 'loading', true) && CloseLoading(apiName, closeLoadingHandle)
      const copyRes = res.data
      // 数据解析
      res = ParseResData(apiName, res.data, parseData)
      // 检查返回code是否为在成功返回码内
      const codeHandleStatus = HandleCode(apiName, res, codeHandle)
      if (CheckSuccessCode(apiName, res, successCode) || ConfigPriority(apiName, forceReturn, 'forceReturn', false)) {
        // 在成功返回码内，回调then
        if (typeof codeHandleStatus === 'undefined' || codeHandleStatus) {
          resolve(copyRes)
        }
      } else {
        // 不在成功返回码，检查是否要提示msg
        CheckIgnoreToastCode(apiName, res, ignoreToastCode) && ToastMsg(apiName, res, toastHandle)
        resolve(copyRes)
      }
    }).catch(erro => {
      // 关闭loadig加载
      ConfigPriority(apiName, loading, 'loading', true) && CloseLoading(apiName, closeLoadingHandle)
      // 调用请求失败回调方法
      const catchHandle = ConfigPriority(apiName, catchRequestHandle, 'catchRequestHandle', () => { alert('网络繁忙') })
      typeof catchHandle === 'function' && catchHandle(erro, resolve, reject)
      reject(erro)
    })
  })
}

// get请求
const Get = ({ url, data, header }) => {
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      params: data,
      headers: header
    }).then(res => {
      resolve(res)
    }).catch(res => {
      reject(res)
    })
  })
}

// post请求
const Post = ({ url, data, header }) => {
  return new Promise((resolve, reject) => {
    Axios.post(url, qs.stringify(data), {
      headers: header
    }).then(res => {
      resolve(res)
    }).catch(res => {
      reject(res)
    })
  })
}

// 上传文件
export const UploadFile = (apiName, file) => {
  return new Promise((resolve, reject) => {
    const apiUrl = GetApi(apiName)
    let formData = new FormData()
    formData.append('file', file)
    Axios.post(apiUrl, formData, {
      timeout: 30000,
      'Content-Type': 'multipart/form-data'
    }).then(res => {
      res = res.data
      resolve(res)
    }).catch(res => {
      console.log(res)
    })
  })
}
