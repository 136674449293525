/**
 * 设置缓存
 */
export const SetStorage = (key, value, type = 'session') => {
    return new Promise((resolve, reject) => {
        switch (type) {
            case 'session':
                try {
                    console.log(value, 'SetStorage')
                    sessionStorage.setItem(key, JSON.stringify(value))
                    resolve()
                } catch (e) {
                    reject(e)
                }
                break
            case 'local':
                try {
                    localStorage.setItem(key, JSON.stringify(value))
                    resolve()
                } catch (e) {
                    reject(e)
                }
                break
        }
    })
}
/**
 * 获取缓存
 */
export const GetStorage = (key, type = 'session') => {
    let r = null
    switch (type) {
        case 'session':
            if (sessionStorage.getItem(key)) {
                r = JSON.parse(sessionStorage.getItem(key))
            }
            break
        case 'local':
            if (localStorage.getItem(key)) {
                r = JSON.parse(localStorage.getItem(key))
            }
            break
    }
    return r
}
/**
 * 获取缓存信息
 */
export const GetStorageInfo = (type = 'session') => {
    return {
        keys: [],
        currentSize: 0,
        limitSize: 0
    }
}
/**
 * 移除指定缓存
 */
export const RemoveStorage = (key, type = 'session') => {
    return new Promise((resolve, reject) => {
        switch (type) {
            case 'session':
                try {
                    sessionStorage.removeItem(key)
                    resolve()
                } catch (e) {
                    reject(e)
                }
                break
            case 'local':
                try {
                    localStorage.removeItem(key)
                    resolve()
                } catch (e) {
                    reject(e)
                }
                break
        }
    })
}
/**
 * 清除所有的storage缓存
 */
export const ClearStorage = (type = 'session') => {
    return new Promise((resolve, reject) => {
        switch (type) {
            case 'session':
                try {
                    sessionStorage.clear()
                    resolve()
                } catch (e) {
                    reject(e)
                }
                break
            case 'local':
                try {
                    localStorage.clear()
                    resolve()
                } catch (e) {
                    reject(e)
                }
                break
        }
    })
}
export default {
    install: Vue => {
        Vue.prototype.$SetStorage = SetStorage
        Vue.prototype.$GetStorage = GetStorage
        Vue.prototype.$RemoveStorage = RemoveStorage
        Vue.prototype.$GetStorageInfo = GetStorageInfo
        Vue.prototype.$ClearStorage = ClearStorage
    },
    SetStorage,
    GetStorage,
    RemoveStorage
}
