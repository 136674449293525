const Config = {
  // 域名
  // hostUrl: 'https://develop.xiandairiyu.cn', // 测试
  hostUrl: 'https://admin.xiandairiyu.cn', // 正式

  // 端名
  controlName: 'Pc',
  // api格式
  apiFormat: 'hostUrl/controlName/modularName/actionName',
  // 全局请求头
  header: {},
  // 返回成功码
  successCode: [200],
  // 忽略提示返回码
  ignoreToastCode: [506, 508, 708],
  token: {
    ZTBTOKEN: 'ZTBTOKEN'
  },
  mapkey: '2M6BZ-NT66U-QKZV4-BZPXS-EA2PE-ABFFQ',
  webUrl: 'http://develop_m.xiandairiyu.cn/#/'
}

export default Config
